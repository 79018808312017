@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600;700&display=swap);
body {
  padding: 0;
  margin: 0;
  font-family: 'EB Garamond', sans-serif;
}

section {
  display: grid;
  place-items: center;
  align-content: center;
  min-height: 100vh;
}


